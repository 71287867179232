<template>
    <div class="p-4">
        <surround-loader :is-loading="isLoading">
            <h4>Upload stats</h4>
            <div id="upload-stats" class="row row-deck text-center">
                <div class="col-3 d-flex flex-column">
                    <b-card class="flex-fill">
                        <div class="display-4">
                            {{ stats.today }}
                        </div>
                        <div class="text-muted">
                            Today
                        </div>
                    </b-card>
                </div>
                <div class="col-3 d-flex flex-column">
                    <b-card class="flex-fill">
                        <div class="display-4">
                            {{ stats.week }}
                        </div>
                        <div class="text-muted">
                            This week
                        </div>
                    </b-card>
                </div>
                <div class="col-3 d-flex flex-column">
                    <b-card class="flex-fill">
                        <div class="display-4">
                            {{ stats.month }}
                        </div>
                        <div class="text-muted">
                            This month
                        </div>
                    </b-card>
                </div>
                <div class="col-3 d-flex flex-column">
                    <b-card class="flex-fill">
                        <div class="display-4">
                            {{ stats.quarter }}
                        </div>
                        <div class="text-muted">
                            This quarter
                        </div>
                    </b-card>
                </div>
            </div>
        </surround-loader>

        <div class="mt-4" id="recent-uploads">
            <h4>Recent uploads</h4>
            <paginated-result-loader :hide-batch-actions="true" endpoint="applications" cell-type="applications"
                                     :filters="[{api_key: 'status','value' : ['approved','flagged','rejected']}]"></paginated-result-loader>
        </div>
    </div>
</template>
<script>
    import PaginatedResultLoader from "../../components/PaginatedResultLoader";
    import {getResource} from "../../modules/api/methods";
    import {applicationStats} from "../../modules/api/endpoints";
    import SurroundLoader from "../../components/SurroundLoader";

    export default {
        components: {SurroundLoader, PaginatedResultLoader},
        name: "DashboardHome",
        data: function () {
            return {
                isLoading: false,
                stats: {}
            }
        },
        created() {
            this.loadStats();
            this.$root.$children[0].checkAnnouncements();
        },
        methods: {
            loadStats() {
                this.isLoading = true;
                getResource(applicationStats).then((resp) => {
                    console.log(resp);
                    this.stats = resp.data.success;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.isLoading = false;
                    this.$root.$children[0].updateCurrentSteps(this.$router.currentRoute);
                });
            }
        }
    }
</script>
